import { refreshToken } from "@/api/authentication";
import config from "@/config";
import {
    buildB2CRedirectUri,
    client,
} from "@/utils/azureB2C";
import { tracing } from "@/utils/tracing";
import { useMsal } from "@azure/msal-react";
import {
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router";

export default function useConditionalLogoutFromB2C () {
    const [ loading, setLoading ] = useState<boolean>(false);
    const history = useHistory();
    const {
        instance
    } = useMsal();
    console.log(history);

    useEffect(() => {
        const callLogoutFromB2C = async () => {
            try {
                setLoading(true);

                const isAuthenticated = await refreshToken();
                console.log("useConditionalLogoutFromB2C isAuthenticated",isAuthenticated)
                if (!isAuthenticated) {
                    const accounts = client.getAllAccounts();
                    console.log("useConditionalLogoutFromB2C accounts.length",accounts.length)
                
                    if (accounts.length) {
                        console.log("useConditionalLogoutFromB2C postLogoutRedirectUri: buildB2CRedirectUri().toString()",{postLogoutRedirectUri:buildB2CRedirectUri().toString()})
                
                        await client.logoutRedirect({
                            postLogoutRedirectUri: buildB2CRedirectUri().toString(),
                        });
                    }
                }
            } catch (error) {
                console.error("useConditionalLogoutFromB2C",error);
                tracing.error(error);
                // instance?.clearCache();
                history.push(`/signin`);
                // history.push(`/error`);
            } finally {
                setLoading(false);
            }
        };
        if (config.azureB2C.enabled) {
            callLogoutFromB2C();
        }
    }, [ history ]);

    return {
        loading,
    };

}
