import { HttpError, HttpStatus } from "@/api/http";
import { DEFAULT_HEADERS, get } from "@/api/rest";
import { tracing } from "@/utils/tracing";

export interface OrganizationLabel {
  logo: string;
  organization_name: string;
  url_query_name: string;
  background_image: string;
  dashboard_css_color: string;
}

export const getLabelByOrganizationName = async (
  organizationName: string
): Promise<OrganizationLabel> => {
  const baseUrl = process.env.WHITE_LABLE_ENDPOINT;
  const url = `${baseUrl}/api/white-lable-organization?url_query_name=${organizationName}`;

  try {
    const response = await get(url, {
      headers: new Headers({
        ...DEFAULT_HEADERS,
      }),
    });

    if (response.status !== HttpStatus.OK) {
      tracing.error(new HttpError(response.status));
    }

    return response.json();
  } catch (e) {
    console.error(e);
    tracing.error(e);
    throw e;
  }
};
