import { transferAzureB2CToken } from "@/api/authentication";
import { transferToken2 } from "@/api/authentication/transfer";
import { openLiveApp } from "@/app";
import Loading from "@/components/Loading";
import {
    Platform,
    useAccessToken,
    useLocale,
    useOAuthLoginState,
    usePlatform,
    useURLContext,
} from "@/hooks";
import useUpdateLocale from "@/hooks/azureB2C/useUpdateLocale";
import { tracing } from "@/utils/tracing";
import { AccountInfo } from "@azure/msal-browser";
import { MsalAuthenticationResult, useMsal } from "@azure/msal-react";
import React,
{ useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

export default function LoggedIn ({result }: MsalAuthenticationResult) {
    
    const {
        instance
    } = useMsal();

    const history = useHistory();
    const [ locale ] = useLocale();
    const urlContext = useURLContext();
    const {
        token,
        account,
        isLoading,
        error: accessTokenError,
    } = useAccessToken(result);


    const isUpdatingLocale = useUpdateLocale(result);

    // We render this component when:
    //      1. You have just created an account/logged into B2C, and been sent back to the app via the OAuth2 `redirect_uri`
    //      2. You are currently authenticated with B2C, and we need to silently retrieve your `accessToken`
    //
    // We rely on the `ua` QueryParam to determine the platform (Android, iOS, Browser), which is state which
    // will be lost in case #1 when the OAuth2 redirect happens.
    // To account for this, we need to store `platform` in the `state` parameter of the OAuth2 request,
    // and retrieve this state after the redirect
    //
    // In case #2, since we don't perform a redirect, the `ua` QueryParam is preserved, so we can
    // fallback on the output of `usePlatform`
    const oauthState = useOAuthLoginState(result);
    const currentPlatform = usePlatform();
    const platform: Platform = oauthState?.platform ?? currentPlatform;

    var planA = false;
    const [ useToken, setUseToken ] = useState<String | null | undefined>('');

    useEffect(() => {
        
        // Need to wait for the following before we can open the mobile app or /transfer:
        // - an `accessToken` to be fetched (if required)
        // - `locale` to be updated
        //     - For mobile app, ensure the latest `locale` is provided
        //     - For browser, when `useUpdateLocale` completes, the react-intl Provider rerenders,
        //       which causes a rerender of this component, which would abort any in-progress
        //       "/transfer" call
        if (isLoading || isUpdatingLocale) return;

        if (!token || accessTokenError) {
            console.error(`Unexpected error retrieving accessToken from B2C`);
            if (accessTokenError) {
                
                console.error(accessTokenError);
                tracing.error(accessTokenError);
            }
            instance?.clearCache();
            history.push(`/signin`);
            // history.push(`/error`);
            return;
        }

        if (platform === `Android`) {
            openLiveApp({
                token,
                domain: urlContext.hostName,
                locale,
            });
            return;
        }

        if (platform === `iOS`) {
            history.push({
                pathname: `/continue`,
                search: `?ua=cordovaios`,
                state: {
                    token,
                },
            });
            return;
        }
        const abortController = new AbortController();
         
        async function transfer (accessToken: string,activeAccount :AccountInfo|undefined) {
            // plan A
            const success = await transferAzureB2CToken(accessToken, abortController);
            console.log("transfer :",success);
            

            if (success) { 
                console.log("plan A::Success");
                setUseToken(accessToken);
                history.push(`/selectprofile`); 
                
            }else if(activeAccount){
                // plan B
                
                const mytoken = await transferToken2(activeAccount,abortController);
                if (mytoken) { 
                    console.log("plan B::Success");
                    setUseToken(mytoken);
                    console.log("plan B::Success ::token",useToken);
                    history.push(`/selectprofile`);
                }

            }else {
                console.error(`Transfer of Azure B2C accessToken to Kidsloop issued JWT failed`);
                history.push(`/error`);
            }
    
            if (platform === `iOS`) {
                history.push({
                    pathname: `/continue`,
                    search: `?ua=cordovaios`,
                    state: {
                        useToken,
                    },
                });
                return;
            }

        }

        transfer(token,account);

        return () => {
            abortController.abort();
        };
    }, [
        accessTokenError,
        token,
        useToken,
        isLoading,
        account,
        isUpdatingLocale,
        history,
        locale,
        platform,
        urlContext.hostName,
    ]);

    return <Loading />;
}
