import { Send as SendIcon } from "@mui/icons-material";
import {
    Button,
    ButtonProps,
    Hidden,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React,
{ ElementType } from "react";

type Props<T extends ElementType<any> = "button"> = ButtonProps<T> & {
    extendedOnly?: boolean;
}

const StyledBtn = withStyles({
    root: {
        "&:hover": {
            "-webkit-transition": `all .4s ease`,
            background: `#1B365D`,
            "box-shadow": `0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)`,
            transform: `translateY(-2px)`,
            transition: `all .4s ease`,
        },
        background: `#0E78D5`,
        borderRadius: 12,
        color: `white`,

    },
})(Button);

export default function StyledButton<T extends ElementType<any> = "button"> (props: Props<T>) {
    const {
        children,
        extendedOnly,
        ...other
    } = props;

    let sibling: React.ReactNode;
    React.Children.map(children, (child) => (
        typeof child !== `string` ? sibling = child : {}
    ));

    return (
        extendedOnly ? (
            <StyledBtn
                style={{
                    minWidth: 120,
                }}
                {...other}
            >
                { children || <SendIcon />}
            </StyledBtn>
        ) : (
            <>
                <Hidden smDown>
                    <StyledBtn
                        style={{
                            minWidth: 120,
                        }}
                        {...other}
                    >
                        { children || <SendIcon />}
                    </StyledBtn>
                </Hidden>
                <Hidden mdUp>
                    <StyledBtn
                        style={{
                            minWidth: 80,
                        }}
                        {...other}
                    >
                        { sibling || <SendIcon /> }
                    </StyledBtn>
                </Hidden>
            </>
        )
    );
}
