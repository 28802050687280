import {
    HttpError,
    HttpStatus,
} from "@/api/http";
import {
    DEFAULT_HEADERS,
    post,
} from "@/api/rest";
import { tracing } from "@/utils/tracing";
import { AccountInfo } from "@azure/msal-browser";

export const transferAMSToken = async (token: string): Promise<boolean> => {
    try {
        const response = await post(`/transfer`, {
            body: JSON.stringify({
                token,
            }),
        });
        if (response.status !== HttpStatus.OK) {
            tracing.error(new HttpError(response.status));
        }
        return response.ok;
    } catch (e) {
        console.error(e);
        tracing.error(e);
        return false;
    }
};

export const transferAzureB2CToken = async (token: string, abortController: AbortController): Promise<boolean> => {
    try {
        const response = await post(`https://auth.kidsloop.id/transfer`, {
            headers: new Headers({
                ...DEFAULT_HEADERS,
                Authorization: `Bearer ${token}`,
            }),
            signal: abortController.signal,
        });

        if (response.status !== HttpStatus.OK) {
            tracing.error(new HttpError(response.status));
        }
        return response.ok;
    } catch (e) {
        console.error(e);
        tracing.error(e);
        return false;
    }
};

export var transferToken2 = async (akun: AccountInfo, abortController: AbortController): Promise<string> => {
    try {

        if(akun===null) tracing.error(new HttpError(400));
        // "id":akun.localAccountId,
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "id":akun?.localAccountId,
                "email":akun.username,
                "phone":"",
                "name":(akun?.name)?akun.name:akun.username
            })
        };
        const response = await fetch('https://auth.kidsloop.id/transfer2', requestOptions);
            
        if (response.status !== HttpStatus.OK) {
            tracing.error(new HttpError(response.status));
        }
        return  response.text();
    } catch (e) {
        console.error(e);
        tracing.error(e);
        return '';
    }
};
