import { useConditionalLogoutFromB2C, useURLContext } from "@/hooks";
import { omitNullish } from "@/utils/object";
import {
  getLabelByOrganizationName,
  OrganizationLabel,
} from "@/api/getOrganizationLogo";
import { Layout } from "./layout";
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, useHistory, useLocation } from "react-router";
import QueryString from "query-string";

export const DOMAINS = [
  `auth.kidsloop.cn`,
  `auth.kidsloop.co.th`,
  `auth.kidsloop.co.uk`,
  `auth.kidsloop.in`,
  `auth.kidsloop.id`,
  `auth.kidsloop.live`,
  `auth.kidsloop.lk`,
  `auth.kidsloop.pk`,
  `auth.kidsloop.vn`,
] as const;

export type Domain = typeof DOMAINS[number];

// export interface Region {
//   img: string;
//   // domain: Domain;
//   primaryText: string;
//   secondaryText: string | React.ReactElement;
// }

// export const regions: Region[] = [
//   {
//     img: UnitedKingdom,
//     domain: `auth.kidsloop.co.uk`,
//     primaryText: `United Kingdom`,
//     secondaryText: ``,
//   },
//   {
//     img: India,
//     domain: `auth.kidsloop.in`,
//     primaryText: `India`,
//     secondaryText: ``,
//   },
//   {
//     img: SriLanka,
//     domain: `auth.kidsloop.lk`,
//     primaryText: `Sri Lanka`,
//     secondaryText: ``,
//   },
//   {
//     img: Pakistan,
//     domain: `auth.kidsloop.pk`,
//     primaryText: `اِسلامی جمہوریہ پاكِستان`,
//     secondaryText: ``,
//   },
//   {
//     img: UnitedStates,
//     domain: `auth.kidsloop.live`,
//     primaryText: `United States`,
//     secondaryText: ``,
//   },
//   {
//     img: Indonesia,
//     domain: `auth.kidsloop.id`,
//     primaryText: `Republik Indonesia`,
//     secondaryText: ``,
//   },
//   {
//     img: Korea,
//     domain: `auth.kidsloop.live`,
//     primaryText: `대한민국`,
//     secondaryText: ``,
//   },
//   {
//     img: Thailand,
//     domain: `auth.kidsloop.co.th`,
//     primaryText: `ประเทศไทย`,
//     secondaryText: ``,
//   },
//   {
//     img: Vietnam,
//     domain: `auth.kidsloop.vn`,
//     primaryText: `Việt Nam`,
//     secondaryText: ``,
//   },
// ];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: `100%`,
      marginTop: 16,
      marginLeft: 16,
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down(`sm`)]: {
        maxHeight: `200px`,
        overflow: `auto`,
        boxShadow: `inset 2px 2px 10px #eaf5f8, inset -2px -2px 10px #c4c4c4`,
        borderRadius: 12,
      },
    },
    card: {
      alignItems: `center`,
      display: `flex`,
      padding: `48px 40px !important`
    },
    errorIcon: {
      fontSize: `1em`,
      marginRight: theme.spacing(1),
    },
    formContainer: {
      width: `100%`,
    },
    pageWrapper: {
      display: `flex`,
      flexGrow: 1,
      height: `100vh`,
    },
    button: {
      marginLeft: 32,
      marginBottom: -16,
    },
  })
);

export function RegionSelect() {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const history = useHistory();
  const url = useURLContext();
  const location = useLocation();
  const { loading } = useConditionalLogoutFromB2C();
  const isSmDown = useMediaQuery(theme.breakpoints.down(`sm`));
  const parsed = QueryString.parse(location.search);
  const organizationName: string =
    parsed?.organization_name == undefined
      ? ""
      : parsed?.organization_name?.toString();

  const [organizationData, setOrganizationData] = React.useState<
    undefined | OrganizationLabel
  >(undefined);
  const [logoLoading, setLogoLoading] = React.useState<boolean>(parsed?.organization_name == undefined?false:true);

  const fetchOrganizationLogo = async () => {
    try {
      setLogoLoading(true);
      const res = await getLabelByOrganizationName(organizationName);
      console.log("Res",res);
      if (res) {
        setOrganizationData(res);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLogoLoading(false);
    }
  };

  React.useEffect(() => {
    if (organizationName) {
      fetchOrganizationLogo();
    }
  }, []);

  const handleRegionSelect = (domain: string) => {
    const protocol = window.location.protocol;
    const port = window.location.port;
    const urlHostName = port === `` ? url.hostName : `${url.hostName}:${port}`;

    if (domain === urlHostName) {
      history.push(`/signin`);
    } else {
      const redirectTo = new URL(`${protocol}//${domain}`);
      redirectTo.search = new URLSearchParams(
        omitNullish({
          ua: url.uaParam,
          continue: url.continueParam,
        })
      ).toString();
      redirectTo.pathname = `/signin`;

      window.location.assign(redirectTo);
    }
  };


  function RenderWhitelable(props:any) {

    console.log("props ",props);
    const loading = props?.loading;
    const data  = props?.data;
    console.log("data ",data);
    console.log("loading ",loading);
    
    if ( loading || (
      data?.logo &&
      data?.organization_name &&
      data?.background_image)
    ) {
      return (
        <Layout
          maxWidth={"sm"}
          loading={loading}
          organizationLogo={data?.logo}
          bgImage={data?.background_image}
          centerLogo={true}
        >
          <div style={{
            width: "648px",
            background: "#FFA015",
            height: '3px',
            borderRadius: 4,
            left: 15,
            marginTop: 35,
            position: "relative",

          }}></div>
          <Grid item xs={12}>
            <Typography variant="h5" style={{
              textAlign: 'center',
              margin: "0 auto",
              fontWeight: 700,
              color: '#000000'
            }}>
              {`Selamat Datang di`}
              <br></br>
              {`${data?.organization_name}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem
                button
                style={{
    
                  textAlign:'center',
                  color: 'white',
                  background: "#B1B1B1",
                  fontSize: 20,
                  lineHeight: 30
                }}
                onClick={() => handleRegionSelect('auth.kidsloop.id')}
              >
                
                <ListItemText
                  primary={"Log in"}
                  // style={{
                  //   paddingLeft: theme.spacing(2),
                  // }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="body2" style={{
              textAlign: 'center',
            }}>
              {`Powered by klaSmart`}
            </Typography>
          </Grid>
        </Layout>
      );
    }else{
      return (
        <Redirect to={{
                pathname: `/signin`,
                }}
              />
      );
    }
  }

  if (loading) {
    return (
        <CircularProgress size={`5rem`} />
    );
  }

  return (
    <RenderWhitelable loading={logoLoading} data={organizationData}/>
  );
}
