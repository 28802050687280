/* eslint-disable */
// This file is automatically generated by @graphql-codegen, do not edit!
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  HexColor: any;
  /** The number of results to return per page */
  PageSize: any;
  /** The `UUID` scalar type represents UUID values as specified by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
  Upload: any;
  Url: any;
  _Any: any;
};

export type AddClassesToSchoolInput = {
  classIds: Array<Scalars['ID']>;
  schoolId: Scalars['ID'];
};

export type AddOrganizationRolesToUserInput = {
  organizationId: Scalars['ID'];
  roleIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type AddProgramsToClassInput = {
  classId: Scalars['ID'];
  programIds: Array<Scalars['ID']>;
};

export type AddProgramsToSchoolInput = {
  programIds: Array<Scalars['ID']>;
  schoolId: Scalars['ID'];
};

export type AddSchoolRolesToUserInput = {
  roleIds: Array<Scalars['ID']>;
  schoolId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AddSubcategoriesToCategoryInput = {
  categoryId: Scalars['ID'];
  subcategoryIds: Array<Scalars['ID']>;
};

export type AddUsersToOrganizationInput = {
  organizationId: Scalars['ID'];
  organizationRoleIds: Array<Scalars['ID']>;
  shortcode?: InputMaybe<Scalars['String']>;
  userIds: Array<Scalars['ID']>;
};

export type AddUsersToSchoolInput = {
  schoolId: Scalars['ID'];
  schoolRoleIds?: InputMaybe<Array<Scalars['ID']>>;
  userIds: Array<Scalars['ID']>;
};

export type AgeRange = {
  delete?: Maybe<Scalars['Boolean']>;
  high_value: Scalars['Int'];
  high_value_unit: AgeRangeUnit;
  id: Scalars['ID'];
  low_value: Scalars['Int'];
  low_value_unit: AgeRangeUnit;
  name: Scalars['String'];
  status?: Maybe<Status>;
  system: Scalars['Boolean'];
};


export type AgeRangeDeleteArgs = {
  _?: InputMaybe<Scalars['Int']>;
};

export type AgeRangeConnectionNode = {
  highValue: Scalars['Int'];
  highValueUnit: AgeRangeUnit;
  id: Scalars['ID'];
  lowValue: Scalars['Int'];
  lowValueUnit: AgeRangeUnit;
  name?: Maybe<Scalars['String']>;
  status: Status;
  system: Scalars['Boolean'];
};

export type AgeRangeDetail = {
  high_value?: InputMaybe<Scalars['Int']>;
  high_value_unit?: InputMaybe<AgeRangeUnit>;
  id?: InputMaybe<Scalars['ID']>;
  low_value?: InputMaybe<Scalars['Int']>;
  low_value_unit?: InputMaybe<AgeRangeUnit>;
  name?: InputMaybe<Scalars['String']>;
  system?: InputMaybe<Scalars['Boolean']>;
};

export type AgeRangeFilter = {
  AND?: InputMaybe<Array<AgeRangeFilter>>;
  OR?: InputMaybe<Array<AgeRangeFilter>>;
  ageRangeUnitFrom?: InputMaybe<AgeRangeUnitFilter>;
  ageRangeUnitTo?: InputMaybe<AgeRangeUnitFilter>;
  ageRangeValueFrom?: InputMaybe<AgeRangeValueFilter>;
  ageRangeValueTo?: InputMaybe<AgeRangeValueFilter>;
  organizationId?: InputMaybe<UuidFilter>;
  programId?: InputMaybe<UuidFilter>;
  status?: InputMaybe<StringFilter>;
  system?: InputMaybe<BooleanFilter>;
};

export enum AgeRangeSortBy {
  Id = 'id',
  LowValue = 'lowValue',
  LowValueUnit = 'lowValueUnit'
}

export type AgeRangeSortInput = {
  field: Array<AgeRangeSortBy>;
  order: SortOrder;
};

export type AgeRangeTypeFilter = {
  operator: NumberOrDateOperator;
  value: AgeRangeValue;
};

export enum AgeRangeUnit {
  Month = 'month',
  Year = 'year'
}

export type AgeRangeUnitFilter = {
  operator: UuidOperator;
  value: AgeRangeUnit;
};

export type AgeRangeValue = {
  unit: AgeRangeUnit;
  value: Scalars['Int'];
};

export type AgeRangeValueFilter = {
  operator: NumberOrDateOperator;
  value: Scalars['Int'];
};

export type AgeRangesConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AgeRangeConnectionNode>;
};

export type AgeRangesConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<AgeRangesConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type BooleanFilter = {
  operator: BooleanOperator;
  value: Scalars['Boolean'];
};

export enum BooleanOperator {
  Eq = 'eq'
}

export type Branding = {
  iconImageURL?: Maybe<Scalars['Url']>;
  primaryColor?: Maybe<Scalars['HexColor']>;
};

export enum BrandingImageTag {
  Icon = 'ICON'
}

export type CategoriesConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CategoryConnectionNode>;
};

export type CategoriesConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<CategoriesConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CategoriesMutationResult = {
  categories: Array<CategoryConnectionNode>;
};

export type Category = {
  /** @deprecated Sunset Date: 24/02/2022 Details: https://calmisland.atlassian.net/l/c/mTni58mA */
  delete?: Maybe<Scalars['Boolean']>;
  /** @deprecated Sunset Date: 22/02/2022 Details: https://calmisland.atlassian.net/l/c/U107XwHS */
  editSubcategories?: Maybe<Array<Maybe<Subcategory>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<Status>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  subcategories?: Maybe<Array<Subcategory>>;
  system: Scalars['Boolean'];
};


export type CategoryDeleteArgs = {
  _?: InputMaybe<Scalars['Int']>;
};


export type CategoryEditSubcategoriesArgs = {
  subcategory_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type CategoryConnectionNode = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status: Status;
  subcategoriesConnection?: Maybe<SubcategoriesConnectionResponse>;
  subjectsConnection?: Maybe<SubjectsConnectionResponse>;
  system: Scalars['Boolean'];
};


export type CategoryConnectionNodeSubcategoriesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SubcategoryFilter>;
  sort?: InputMaybe<SubcategorySortInput>;
};


export type CategoryConnectionNodeSubjectsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SubjectFilter>;
  sort?: InputMaybe<SubjectSortInput>;
};

export type CategoryDetail = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  subcategories?: InputMaybe<Array<Scalars['ID']>>;
  system?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<CategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CategoryFilter>>>;
  status?: InputMaybe<StringFilter>;
  system?: InputMaybe<BooleanFilter>;
};

export enum CategorySortBy {
  Id = 'id',
  Name = 'name'
}

export type CategorySortInput = {
  field: CategorySortBy;
  order: SortOrder;
};

export type Class = {
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/l/c/av1p2bKY */
  addSchool?: Maybe<School>;
  addStudent?: Maybe<User>;
  addTeacher?: Maybe<User>;
  age_ranges?: Maybe<Array<AgeRange>>;
  class_id: Scalars['ID'];
  class_name?: Maybe<Scalars['String']>;
  /** @deprecated Use deleteClasses() method */
  delete?: Maybe<Scalars['Boolean']>;
  editAgeRanges?: Maybe<Array<Maybe<AgeRange>>>;
  editGrades?: Maybe<Array<Maybe<Grade>>>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/l/c/av1p2bKY */
  editPrograms?: Maybe<Array<Maybe<Program>>>;
  editSchools?: Maybe<Array<Maybe<School>>>;
  editStudents?: Maybe<Array<Maybe<User>>>;
  editSubjects?: Maybe<Array<Maybe<Subject>>>;
  editTeachers?: Maybe<Array<Maybe<User>>>;
  /** @deprecated Sunset Date: 31/03/2022 Details: [https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2478735554] */
  eligibleStudents?: Maybe<Array<Maybe<User>>>;
  /** @deprecated Sunset Date: 31/03/2022 Details: [https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2478735554] */
  eligibleTeachers?: Maybe<Array<Maybe<User>>>;
  grades?: Maybe<Array<Grade>>;
  organization?: Maybe<Organization>;
  programs?: Maybe<Array<Program>>;
  removeSchool?: Maybe<Scalars['Boolean']>;
  removeStudent?: Maybe<Scalars['Boolean']>;
  removeTeacher?: Maybe<Scalars['Boolean']>;
  schools?: Maybe<Array<Maybe<School>>>;
  set?: Maybe<Class>;
  shortcode?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  students?: Maybe<Array<Maybe<User>>>;
  subjects?: Maybe<Array<Subject>>;
  teachers?: Maybe<Array<Maybe<User>>>;
};


export type ClassAddSchoolArgs = {
  school_id: Scalars['ID'];
};


export type ClassAddStudentArgs = {
  user_id: Scalars['ID'];
};


export type ClassAddTeacherArgs = {
  user_id: Scalars['ID'];
};


export type ClassDeleteArgs = {
  _?: InputMaybe<Scalars['Int']>;
};


export type ClassEditAgeRangesArgs = {
  age_range_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type ClassEditGradesArgs = {
  grade_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type ClassEditProgramsArgs = {
  program_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type ClassEditSchoolsArgs = {
  school_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type ClassEditStudentsArgs = {
  student_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type ClassEditSubjectsArgs = {
  subject_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type ClassEditTeachersArgs = {
  teacher_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type ClassRemoveSchoolArgs = {
  school_id: Scalars['ID'];
};


export type ClassRemoveStudentArgs = {
  user_id: Scalars['ID'];
};


export type ClassRemoveTeacherArgs = {
  user_id: Scalars['ID'];
};


export type ClassSetArgs = {
  class_name?: InputMaybe<Scalars['String']>;
  shortcode?: InputMaybe<Scalars['String']>;
};

export type ClassConnectionNode = {
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  ageRanges?: Maybe<Array<AgeRangeConnectionNode>>;
  ageRangesConnection?: Maybe<AgeRangesConnectionResponse>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  grades?: Maybe<Array<GradeSummaryNode>>;
  gradesConnection?: Maybe<GradesConnectionResponse>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** @deprecated Sunset Date: 01/03/22 Details: https://calmisland.atlassian.net/l/c/aaSJnmbQ */
  programs?: Maybe<Array<CoreProgramConnectionNode>>;
  programsConnection?: Maybe<ProgramsConnectionResponse>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  schools?: Maybe<Array<SchoolSummaryNode>>;
  schoolsConnection?: Maybe<SchoolsConnectionResponse>;
  shortCode?: Maybe<Scalars['String']>;
  status: Status;
  studentsConnection?: Maybe<UsersConnectionResponse>;
  /** @deprecated Sunset Date: 07/03/2022 Details: https://calmisland.atlassian.net/l/c/Ts9fp60C */
  subjects?: Maybe<Array<CoreSubjectConnectionNode>>;
  subjectsConnection?: Maybe<SubjectsConnectionResponse>;
  teachersConnection?: Maybe<UsersConnectionResponse>;
};


export type ClassConnectionNodeAgeRangesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction: ConnectionDirection;
  filter?: InputMaybe<AgeRangeFilter>;
  sort?: InputMaybe<AgeRangeSortInput>;
};


export type ClassConnectionNodeGradesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<GradeFilter>;
  sort?: InputMaybe<GradeSortInput>;
};


export type ClassConnectionNodeProgramsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<ProgramFilter>;
  sort?: InputMaybe<ProgramSortInput>;
};


export type ClassConnectionNodeSchoolsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SchoolFilter>;
  sort?: InputMaybe<SchoolSortInput>;
};


export type ClassConnectionNodeStudentsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<UserSortInput>;
};


export type ClassConnectionNodeSubjectsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SubjectFilter>;
  sort?: InputMaybe<SubjectSortInput>;
};


export type ClassConnectionNodeTeachersConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<UserSortInput>;
};

export type ClassFilter = {
  AND?: InputMaybe<Array<ClassFilter>>;
  OR?: InputMaybe<Array<ClassFilter>>;
  ageRangeUnitFrom?: InputMaybe<AgeRangeUnitFilter>;
  ageRangeUnitTo?: InputMaybe<AgeRangeUnitFilter>;
  ageRangeValueFrom?: InputMaybe<AgeRangeValueFilter>;
  ageRangeValueTo?: InputMaybe<AgeRangeValueFilter>;
  gradeId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<UuidFilter>;
  programId?: InputMaybe<UuidFilter>;
  schoolId?: InputMaybe<UuidExclusiveFilter>;
  status?: InputMaybe<StringFilter>;
  studentId?: InputMaybe<UuidFilter>;
  subjectId?: InputMaybe<UuidFilter>;
  teacherId?: InputMaybe<UuidFilter>;
};

export enum ClassSortBy {
  Id = 'id',
  Name = 'name'
}

export type ClassSortInput = {
  field: ClassSortBy;
  order: SortOrder;
};

export type ClassesConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ClassConnectionNode>;
};

export type ClassesConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<ClassesConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ClassesMutationResult = {
  classes: Array<ClassConnectionNode>;
};

export type Complexity = {
  limit?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
};

export enum ConnectionDirection {
  Backward = 'BACKWARD',
  Forward = 'FORWARD'
}

export type ConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type ConnectionsDirectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
};

export type ContactInfo = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ContactInfoInput = {
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CoreProgramConnectionNode = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status: Status;
  system: Scalars['Boolean'];
};

export type CoreSubjectConnectionNode = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status: Status;
  system: Scalars['Boolean'];
};

export type CreateCategoryInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  subcategories?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateClassInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  shortcode?: InputMaybe<Scalars['String']>;
};

export type CreateRoleInput = {
  organizationId: Scalars['ID'];
  roleDescription: Scalars['String'];
  roleName: Scalars['String'];
};

export type CreateSchoolInput = {
  name: Scalars['String'];
  organizationId: Scalars['String'];
  shortCode?: InputMaybe<Scalars['String']>;
};

export type CreateSubcategoryInput = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CreateSubjectInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CreateUserInput = {
  alternateEmail?: InputMaybe<Scalars['String']>;
  alternatePhone?: InputMaybe<Scalars['String']>;
  contactInfo: ContactInfoInput;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  familyName: Scalars['String'];
  gender: Scalars['String'];
  givenName: Scalars['String'];
  shortcode?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type DateFilter = {
  operator: NumberOrDateOperator;
  value: Scalars['String'];
};

export type DeleteCategoryInput = {
  id: Scalars['ID'];
};

export type DeleteClassInput = {
  id: Scalars['ID'];
};

export type DeleteRoleInput = {
  id: Scalars['ID'];
};

export type DeleteSchoolInput = {
  id: Scalars['ID'];
};

export type DeleteSubcategoryInput = {
  id: Scalars['ID'];
};

export type EligibleMembersFilter = {
  AND?: InputMaybe<Array<EligibleMembersFilter>>;
  OR?: InputMaybe<Array<EligibleMembersFilter>>;
  email?: InputMaybe<StringFilter>;
  familyName?: InputMaybe<StringFilter>;
  givenName?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
};

export type File = {
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type Grade = {
  delete?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  progress_from_grade?: Maybe<Grade>;
  progress_to_grade?: Maybe<Grade>;
  status?: Maybe<Status>;
  system: Scalars['Boolean'];
};


export type GradeDeleteArgs = {
  _?: InputMaybe<Scalars['Int']>;
};

export type GradeConnectionNode = {
  fromGrade: GradeSummaryNode;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status: Status;
  system: Scalars['Boolean'];
  toGrade: GradeSummaryNode;
};

export type GradeDetail = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  progress_from_grade_id?: InputMaybe<Scalars['ID']>;
  progress_to_grade_id?: InputMaybe<Scalars['ID']>;
  system?: InputMaybe<Scalars['Boolean']>;
};

export type GradeFilter = {
  AND?: InputMaybe<Array<GradeFilter>>;
  OR?: InputMaybe<Array<GradeFilter>>;
  fromGradeId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<UuidFilter>;
  programId?: InputMaybe<UuidFilter>;
  status?: InputMaybe<StringFilter>;
  system?: InputMaybe<BooleanFilter>;
  toGradeId?: InputMaybe<UuidFilter>;
};

export enum GradeSortBy {
  Id = 'id',
  Name = 'name'
}

export type GradeSortInput = {
  field: Array<GradeSortBy>;
  order: SortOrder;
};

export type GradeSummaryNode = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status: Status;
  system: Scalars['Boolean'];
};

export type GradesConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<GradeConnectionNode>;
};

export type GradesConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<GradesConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum LogicalOperator {
  And = 'AND',
  Or = 'OR'
}

export type MembershipUpdate = {
  membership?: Maybe<OrganizationMembership>;
  schoolMemberships?: Maybe<Array<Maybe<SchoolMembership>>>;
  user?: Maybe<User>;
};

export type Mutation = {
  addClassesToSchools?: Maybe<SchoolsMutationResult>;
  addOrganizationRolesToUsers?: Maybe<UsersMutationResult>;
  addProgramsToClasses?: Maybe<ClassesMutationResult>;
  addProgramsToSchools?: Maybe<SchoolsMutationResult>;
  addSchoolRolesToUsers?: Maybe<UsersMutationResult>;
  addSubcategoriesToCategories?: Maybe<CategoriesMutationResult>;
  addUsersToOrganizations?: Maybe<OrganizationsMutationResult>;
  addUsersToSchools?: Maybe<SchoolsMutationResult>;
  age_range?: Maybe<AgeRange>;
  /** @deprecated Sunset Date: 24/02/2022 Details: [https://calmisland.atlassian.net/l/c/RKcPTt1p, https://calmisland.atlassian.net/l/c/mTni58mA] */
  category?: Maybe<Category>;
  class?: Maybe<Class>;
  classes?: Maybe<Array<Maybe<Class>>>;
  createCategories?: Maybe<CategoriesMutationResult>;
  createClasses?: Maybe<ClassesMutationResult>;
  createRoles?: Maybe<RolesMutationResult>;
  createSchools?: Maybe<SchoolsMutationResult>;
  createSubcategories?: Maybe<SubcategoriesMutationResult>;
  createSubjects?: Maybe<SubjectsMutationOutput>;
  createUsers?: Maybe<UsersMutationResult>;
  deleteBrandingColor?: Maybe<Scalars['Boolean']>;
  deleteBrandingImage?: Maybe<Scalars['Boolean']>;
  deleteCategories?: Maybe<CategoriesMutationResult>;
  deleteClasses?: Maybe<ClassesMutationResult>;
  deleteRoles?: Maybe<RolesMutationResult>;
  deleteSchools?: Maybe<SchoolsMutationResult>;
  deleteSubcategories?: Maybe<SubcategoriesMutationResult>;
  grade?: Maybe<Grade>;
  me?: Maybe<User>;
  /** @deprecated Use the inviteUser() method */
  newUser?: Maybe<User>;
  organization?: Maybe<Organization>;
  program?: Maybe<Program>;
  removeClassesFromSchools?: Maybe<SchoolsMutationResult>;
  removeOrganizationRolesFromUsers?: Maybe<UsersMutationResult>;
  removeProgramsFromClasses?: Maybe<ClassesMutationResult>;
  removeProgramsFromSchools?: Maybe<SchoolsMutationResult>;
  removeSchoolRolesFromUsers?: Maybe<UsersMutationResult>;
  removeSubcategoriesFromCategories?: Maybe<CategoriesMutationResult>;
  removeUsersFromOrganizations?: Maybe<OrganizationsMutationResult>;
  removeUsersFromSchools?: Maybe<SchoolsMutationResult>;
  renameDuplicateGrades?: Maybe<Scalars['Boolean']>;
  renameDuplicateOrganizations?: Maybe<Scalars['Boolean']>;
  renameDuplicateSubjects?: Maybe<Scalars['Boolean']>;
  replaceRole?: Maybe<Role>;
  role?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  school?: Maybe<School>;
  setBranding?: Maybe<Branding>;
  /** @deprecated Sunset Date: 22/02/22 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2457174175 */
  subcategory?: Maybe<Subcategory>;
  subject?: Maybe<Subject>;
  /** @deprecated Moved to auth service */
  switch_user?: Maybe<User>;
  updateCategories?: Maybe<CategoriesMutationResult>;
  updateClasses?: Maybe<ClassesMutationResult>;
  updateRoles?: Maybe<RolesMutationResult>;
  updateSchools?: Maybe<SchoolsMutationResult>;
  updateSubcategories?: Maybe<SubcategoriesMutationResult>;
  updateUsers?: Maybe<UsersMutationResult>;
  uploadAgeRangesFromCSV?: Maybe<File>;
  uploadCategoriesFromCSV?: Maybe<File>;
  uploadClassesFromCSV?: Maybe<File>;
  uploadGradesFromCSV?: Maybe<File>;
  uploadOrganizationsFromCSV?: Maybe<File>;
  uploadProgramsFromCSV?: Maybe<File>;
  uploadRolesFromCSV?: Maybe<File>;
  uploadSchoolsFromCSV?: Maybe<File>;
  uploadSubCategoriesFromCSV?: Maybe<File>;
  uploadSubjectsFromCSV?: Maybe<File>;
  uploadUsersFromCSV?: Maybe<File>;
  user?: Maybe<User>;
};


export type MutationAddClassesToSchoolsArgs = {
  input: Array<AddClassesToSchoolInput>;
};


export type MutationAddOrganizationRolesToUsersArgs = {
  input: Array<AddOrganizationRolesToUserInput>;
};


export type MutationAddProgramsToClassesArgs = {
  input: Array<AddProgramsToClassInput>;
};


export type MutationAddProgramsToSchoolsArgs = {
  input: Array<AddProgramsToSchoolInput>;
};


export type MutationAddSchoolRolesToUsersArgs = {
  input: Array<AddSchoolRolesToUserInput>;
};


export type MutationAddSubcategoriesToCategoriesArgs = {
  input: Array<AddSubcategoriesToCategoryInput>;
};


export type MutationAddUsersToOrganizationsArgs = {
  input: Array<AddUsersToOrganizationInput>;
};


export type MutationAddUsersToSchoolsArgs = {
  input: Array<AddUsersToSchoolInput>;
};


export type MutationAge_RangeArgs = {
  id: Scalars['ID'];
};


export type MutationCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationClassArgs = {
  class_id: Scalars['ID'];
};


export type MutationCreateCategoriesArgs = {
  input: Array<CreateCategoryInput>;
};


export type MutationCreateClassesArgs = {
  input: Array<CreateClassInput>;
};


export type MutationCreateRolesArgs = {
  input: Array<CreateRoleInput>;
};


export type MutationCreateSchoolsArgs = {
  input: Array<CreateSchoolInput>;
};


export type MutationCreateSubcategoriesArgs = {
  input: Array<CreateSubcategoryInput>;
};


export type MutationCreateSubjectsArgs = {
  input: Array<CreateSubjectInput>;
};


export type MutationCreateUsersArgs = {
  input: Array<CreateUserInput>;
};


export type MutationDeleteBrandingColorArgs = {
  organizationId: Scalars['ID'];
};


export type MutationDeleteBrandingImageArgs = {
  organizationId: Scalars['ID'];
  type: BrandingImageTag;
};


export type MutationDeleteCategoriesArgs = {
  input: Array<DeleteCategoryInput>;
};


export type MutationDeleteClassesArgs = {
  input: Array<DeleteClassInput>;
};


export type MutationDeleteRolesArgs = {
  input: Array<DeleteRoleInput>;
};


export type MutationDeleteSchoolsArgs = {
  input: Array<DeleteSchoolInput>;
};


export type MutationDeleteSubcategoriesArgs = {
  input: Array<DeleteSubcategoryInput>;
};


export type MutationGradeArgs = {
  id: Scalars['ID'];
};


export type MutationNewUserArgs = {
  avatar?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  family_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  given_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationOrganizationArgs = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  organization_id: Scalars['ID'];
  organization_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  shortCode?: InputMaybe<Scalars['String']>;
};


export type MutationProgramArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveClassesFromSchoolsArgs = {
  input: Array<RemoveClassesFromSchoolInput>;
};


export type MutationRemoveOrganizationRolesFromUsersArgs = {
  input: Array<RemoveOrganizationRolesFromUserInput>;
};


export type MutationRemoveProgramsFromClassesArgs = {
  input: Array<RemoveProgramsFromClassInput>;
};


export type MutationRemoveProgramsFromSchoolsArgs = {
  input: Array<RemoveProgramsFromSchoolInput>;
};


export type MutationRemoveSchoolRolesFromUsersArgs = {
  input: Array<RemoveSchoolRolesFromUserInput>;
};


export type MutationRemoveSubcategoriesFromCategoriesArgs = {
  input: Array<RemoveSubcategoriesFromCategoryInput>;
};


export type MutationRemoveUsersFromOrganizationsArgs = {
  input: Array<RemoveUsersFromOrganizationInput>;
};


export type MutationRemoveUsersFromSchoolsArgs = {
  input: Array<RemoveUsersFromSchoolInput>;
};


export type MutationReplaceRoleArgs = {
  new_role_id: Scalars['ID'];
  old_role_id: Scalars['ID'];
  organization_id: Scalars['ID'];
};


export type MutationRoleArgs = {
  role_id: Scalars['ID'];
};


export type MutationSchoolArgs = {
  school_id: Scalars['ID'];
};


export type MutationSetBrandingArgs = {
  iconImage?: InputMaybe<Scalars['Upload']>;
  organizationId: Scalars['ID'];
  primaryColor?: InputMaybe<Scalars['HexColor']>;
};


export type MutationSubcategoryArgs = {
  id: Scalars['ID'];
};


export type MutationSubjectArgs = {
  id: Scalars['ID'];
};


export type MutationSwitch_UserArgs = {
  user_id: Scalars['ID'];
};


export type MutationUpdateCategoriesArgs = {
  input: Array<UpdateCategoryInput>;
};


export type MutationUpdateClassesArgs = {
  input: Array<UpdateClassInput>;
};


export type MutationUpdateRolesArgs = {
  input: Array<UpdateRoleInput>;
};


export type MutationUpdateSchoolsArgs = {
  input: Array<UpdateSchoolInput>;
};


export type MutationUpdateSubcategoriesArgs = {
  input: Array<UpdateSubcategoryInput>;
};


export type MutationUpdateUsersArgs = {
  input: Array<UpdateUserInput>;
};


export type MutationUploadAgeRangesFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadCategoriesFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadClassesFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadGradesFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadOrganizationsFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadProgramsFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadRolesFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadSchoolsFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadSubCategoriesFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadSubjectsFromCsvArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadUsersFromCsvArgs = {
  file: Scalars['Upload'];
  isDryRun?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUserArgs = {
  alternate_email?: InputMaybe<Scalars['String']>;
  alternate_phone?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  family_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  given_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  user_id: Scalars['ID'];
  username?: InputMaybe<Scalars['String']>;
};

export type MyType = {
  myField?: Maybe<Scalars['UUID']>;
};

export type MyUser = {
  hasPermissionsInOrganization: Array<UserPermissionStatus>;
  hasPermissionsInSchool: Array<UserPermissionStatus>;
  node?: Maybe<UserConnectionNode>;
  /** 'operator' default = 'AND' */
  organizationsWithPermissions?: Maybe<OrganizationsConnectionResponse>;
  /** Returns a paginated response of the permissions the user has in a given organization. */
  permissionsInOrganization?: Maybe<PermissionsConnectionResponse>;
  /** Returns a paginated response of the permissions the user has in a given school. */
  permissionsInSchool?: Maybe<PermissionsConnectionResponse>;
  /**
   * Finds user profiles matching the username/email/phone provided in the token.
   * Properties are checked in the following order of precedence: username>email>phone.
   * e.g. email & phone won't be checked if username is provided.
   */
  profiles: Array<UserConnectionNode>;
  /** 'operator' default = 'AND' */
  schoolsWithPermissions?: Maybe<SchoolsConnectionResponse>;
};


export type MyUserHasPermissionsInOrganizationArgs = {
  organizationId: Scalars['ID'];
  permissionIds: Array<Scalars['String']>;
};


export type MyUserHasPermissionsInSchoolArgs = {
  permissionIds: Array<Scalars['String']>;
  schoolId: Scalars['ID'];
};


export type MyUserOrganizationsWithPermissionsArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<OrganizationFilter>;
  operator?: InputMaybe<LogicalOperator>;
  permissionIds: Array<Scalars['String']>;
  sort?: InputMaybe<OrganizationSortInput>;
};


export type MyUserPermissionsInOrganizationArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<PermissionFilter>;
  organizationId: Scalars['ID'];
  sort?: InputMaybe<PermissionSortInput>;
};


export type MyUserPermissionsInSchoolArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<PermissionFilter>;
  schoolId: Scalars['ID'];
  sort?: InputMaybe<PermissionSortInput>;
};


export type MyUserSchoolsWithPermissionsArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SchoolFilter>;
  operator?: InputMaybe<LogicalOperator>;
  permissionIds: Array<Scalars['String']>;
  sort?: InputMaybe<SchoolSortInput>;
};

export type NumberFilter = {
  operator: NumberOrDateOperator;
  value: Scalars['Float'];
};

export enum NumberOrDateOperator {
  Eq = 'eq',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  Neq = 'neq'
}

export type Organization = {
  /** @deprecated Sunset Date: 01/02/22 Details: https://calmisland.atlassian.net/wiki/spaces/UserService/pages/2462417870/ */
  addUser?: Maybe<OrganizationMembership>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  ageRanges?: Maybe<Array<AgeRange>>;
  branding?: Maybe<Branding>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  categories?: Maybe<Array<Category>>;
  /** @deprecated Use 'getClasses'. */
  classes?: Maybe<Array<Maybe<Class>>>;
  /** @deprecated Sunset Date: 10/04/2022 https://calmisland.atlassian.net/l/c/GSPr3XYb */
  createClass?: Maybe<Class>;
  createOrUpdateAgeRanges?: Maybe<Array<Maybe<AgeRange>>>;
  /** @deprecated Sunset Date: 22/02/22 Details: https://calmisland.atlassian.net/l/c/kY3S0K0h */
  createOrUpdateCategories?: Maybe<Array<Maybe<Category>>>;
  createOrUpdateGrades?: Maybe<Array<Maybe<Grade>>>;
  createOrUpdatePrograms?: Maybe<Array<Maybe<Program>>>;
  /** @deprecated Sunset Date: 22/02/22 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2457174175 */
  createOrUpdateSubcategories?: Maybe<Array<Maybe<Subcategory>>>;
  /** @deprecated Sunset Date: 11/04/2022 Details: https://calmisland.atlassian.net/l/c/8d8mpL0Q */
  createOrUpdateSubjects?: Maybe<Array<Maybe<Subject>>>;
  /** @deprecated Sunset Date: 29/03/2022 Details: https://calmisland.atlassian.net/l/c/8d8mpL0Q */
  createRole?: Maybe<Role>;
  createSchool?: Maybe<School>;
  delete?: Maybe<Scalars['Boolean']>;
  /** @deprecated Sunset Date: 01/02/22 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2433581057 */
  editMembership?: Maybe<MembershipUpdate>;
  findMembers?: Maybe<Array<Maybe<OrganizationMembership>>>;
  getClasses?: Maybe<Array<Maybe<Class>>>;
  grades?: Maybe<Array<Grade>>;
  inviteUser?: Maybe<MembershipUpdate>;
  membersWithPermission?: Maybe<Array<Maybe<OrganizationMembership>>>;
  memberships?: Maybe<Array<Maybe<OrganizationMembership>>>;
  organization_id: Scalars['ID'];
  organization_name?: Maybe<Scalars['String']>;
  /**
   * 'owner' is the User that created this Organization
   * @deprecated Use 'organization_ownerships'.
   */
  owner?: Maybe<User>;
  phone?: Maybe<Scalars['String']>;
  primary_contact?: Maybe<User>;
  programs?: Maybe<Array<Program>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  schools?: Maybe<Array<Maybe<School>>>;
  set?: Maybe<Organization>;
  setPrimaryContact?: Maybe<User>;
  shortCode?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  students?: Maybe<Array<Maybe<OrganizationMembership>>>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  subcategories?: Maybe<Array<Subcategory>>;
  subjects?: Maybe<Array<Subject>>;
  teachers?: Maybe<Array<Maybe<OrganizationMembership>>>;
};


export type OrganizationAddUserArgs = {
  shortcode?: InputMaybe<Scalars['String']>;
  user_id: Scalars['ID'];
};


export type OrganizationCreateClassArgs = {
  class_name?: InputMaybe<Scalars['String']>;
  shortcode?: InputMaybe<Scalars['String']>;
};


export type OrganizationCreateOrUpdateAgeRangesArgs = {
  age_ranges: Array<InputMaybe<AgeRangeDetail>>;
};


export type OrganizationCreateOrUpdateCategoriesArgs = {
  categories: Array<InputMaybe<CategoryDetail>>;
};


export type OrganizationCreateOrUpdateGradesArgs = {
  grades: Array<InputMaybe<GradeDetail>>;
};


export type OrganizationCreateOrUpdateProgramsArgs = {
  programs: Array<InputMaybe<ProgramDetail>>;
};


export type OrganizationCreateOrUpdateSubcategoriesArgs = {
  subcategories: Array<InputMaybe<SubcategoryDetail>>;
};


export type OrganizationCreateOrUpdateSubjectsArgs = {
  subjects: Array<InputMaybe<SubjectDetail>>;
};


export type OrganizationCreateRoleArgs = {
  role_description: Scalars['String'];
  role_name: Scalars['String'];
};


export type OrganizationCreateSchoolArgs = {
  school_name?: InputMaybe<Scalars['String']>;
  shortcode?: InputMaybe<Scalars['String']>;
};


export type OrganizationDeleteArgs = {
  _?: InputMaybe<Scalars['Int']>;
};


export type OrganizationEditMembershipArgs = {
  alternate_email?: InputMaybe<Scalars['String']>;
  alternate_phone?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  family_name: Scalars['String'];
  gender: Scalars['String'];
  given_name: Scalars['String'];
  organization_role_ids: Array<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  school_ids?: InputMaybe<Array<Scalars['ID']>>;
  school_role_ids?: InputMaybe<Array<Scalars['ID']>>;
  shortcode: Scalars['String'];
  user_id: Scalars['ID'];
  username?: InputMaybe<Scalars['String']>;
};


export type OrganizationFindMembersArgs = {
  search_query: Scalars['String'];
};


export type OrganizationInviteUserArgs = {
  alternate_email?: InputMaybe<Scalars['String']>;
  alternate_phone?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  family_name: Scalars['String'];
  gender: Scalars['String'];
  given_name: Scalars['String'];
  organization_role_ids: Array<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  school_ids?: InputMaybe<Array<Scalars['ID']>>;
  school_role_ids?: InputMaybe<Array<Scalars['ID']>>;
  shortcode?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export type OrganizationMembersWithPermissionArgs = {
  permission_name: Scalars['String'];
  search_query?: InputMaybe<Scalars['String']>;
};


export type OrganizationSetArgs = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  organization_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  shortCode?: InputMaybe<Scalars['String']>;
};


export type OrganizationSetPrimaryContactArgs = {
  user_id: Scalars['ID'];
};

export type OrganizationConnectionNode = {
  ageRangesConnection?: Maybe<AgeRangesConnectionResponse>;
  branding?: Maybe<Branding>;
  categoriesConnection?: Maybe<CategoriesConnectionResponse>;
  classesConnection?: Maybe<ClassesConnectionResponse>;
  contactInfo?: Maybe<OrganizationContactInfo>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organizationMembershipsConnection?: Maybe<OrganizationMembershipsConnectionResponse>;
  owners?: Maybe<Array<Maybe<UserSummaryNode>>>;
  rolesConnection?: Maybe<RolesConnectionResponse>;
  schoolsConnection?: Maybe<SchoolsConnectionResponse>;
  shortCode?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  subcategoriesConnection?: Maybe<SubcategoriesConnectionResponse>;
};


export type OrganizationConnectionNodeAgeRangesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction: ConnectionDirection;
  filter?: InputMaybe<AgeRangeFilter>;
  sort?: InputMaybe<AgeRangeSortInput>;
};


export type OrganizationConnectionNodeCategoriesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<CategoryFilter>;
  sort?: InputMaybe<CategorySortInput>;
};


export type OrganizationConnectionNodeClassesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<ClassFilter>;
  sort?: InputMaybe<ClassSortInput>;
};


export type OrganizationConnectionNodeOrganizationMembershipsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<OrganizationMembershipFilter>;
  sort?: InputMaybe<OrganizationMembershipSortBy>;
};


export type OrganizationConnectionNodeRolesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<RoleFilter>;
  sort?: InputMaybe<RoleSortInput>;
};


export type OrganizationConnectionNodeSchoolsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SchoolFilter>;
  sort?: InputMaybe<SchoolSortInput>;
};


export type OrganizationConnectionNodeSubcategoriesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SubcategoryFilter>;
  sort?: InputMaybe<SubcategorySortInput>;
};

export type OrganizationContactInfo = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type OrganizationFilter = {
  AND?: InputMaybe<Array<OrganizationFilter>>;
  OR?: InputMaybe<Array<OrganizationFilter>>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  ownerUserEmail?: InputMaybe<StringFilter>;
  ownerUserId?: InputMaybe<UuidFilter>;
  phone?: InputMaybe<StringFilter>;
  shortCode?: InputMaybe<StringFilter>;
  status?: InputMaybe<StringFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type OrganizationMembership = {
  /** @deprecated Sunset Date: 01/02/22 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2433482757 */
  addRole?: Maybe<Role>;
  /** @deprecated Sunset Date: 01/02/22 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2433482757 */
  addRoles?: Maybe<Array<Maybe<Role>>>;
  checkAllowed?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use User.classesStudying and User.classesTeaching */
  classes?: Maybe<Array<Maybe<Class>>>;
  classesTeaching?: Maybe<Array<Maybe<Class>>>;
  join_timestamp?: Maybe<Scalars['Date']>;
  /** @deprecated Sunset Date: 13/03/22 Details: https://calmisland.atlassian.net/wiki/spaces/UserService/pages/2484240385/ */
  leave?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Organization>;
  organization_id: Scalars['ID'];
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2440790112 */
  removeRole?: Maybe<OrganizationMembership>;
  roles?: Maybe<Array<Maybe<Role>>>;
  schoolMemberships?: Maybe<Array<Maybe<SchoolMembership>>>;
  shortcode?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  user?: Maybe<User>;
  user_id: Scalars['ID'];
};


export type OrganizationMembershipAddRoleArgs = {
  role_id: Scalars['ID'];
};


export type OrganizationMembershipAddRolesArgs = {
  role_ids: Array<Scalars['ID']>;
};


export type OrganizationMembershipCheckAllowedArgs = {
  permission_name: Scalars['ID'];
};


export type OrganizationMembershipLeaveArgs = {
  _?: InputMaybe<Scalars['Int']>;
};


export type OrganizationMembershipRemoveRoleArgs = {
  role_id: Scalars['ID'];
};


export type OrganizationMembershipSchoolMembershipsArgs = {
  permission_name?: InputMaybe<Scalars['String']>;
};

export type OrganizationMembershipConnectionNode = {
  joinTimestamp?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationConnectionNode>;
  organizationId: Scalars['String'];
  rolesConnection?: Maybe<RolesConnectionResponse>;
  shortCode?: Maybe<Scalars['String']>;
  status: Status;
  user?: Maybe<UserConnectionNode>;
  userId: Scalars['String'];
};


export type OrganizationMembershipConnectionNodeRolesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<RoleFilter>;
  sort?: InputMaybe<RoleSortInput>;
};

export type OrganizationMembershipFilter = {
  AND?: InputMaybe<Array<InputMaybe<OrganizationMembershipFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<OrganizationMembershipFilter>>>;
  organizationId?: InputMaybe<UuidFilter>;
  roleId?: InputMaybe<UuidFilter>;
  shortCode?: InputMaybe<StringFilter>;
  status?: InputMaybe<StringFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export enum OrganizationMembershipSortBy {
  OrganizationId = 'organizationId',
  UserId = 'userId'
}

export type OrganizationMembershipSortInput = {
  field: OrganizationMembershipSortBy;
  order: SortOrder;
};

export type OrganizationMembershipsConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<OrganizationMembershipConnectionNode>;
};

export type OrganizationMembershipsConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<OrganizationMembershipsConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OrganizationOwnership = {
  organization?: Maybe<Organization>;
  organization_id: Scalars['ID'];
  status?: Maybe<Status>;
  user?: Maybe<User>;
  user_id: Scalars['ID'];
};

export enum OrganizationSortBy {
  Name = 'name',
  OwnerEmail = 'ownerEmail'
}

export type OrganizationSortInput = {
  field: Array<OrganizationSortBy>;
  order: SortOrder;
};

export type OrganizationSummaryNode = {
  id: Scalars['ID'];
  joinDate?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userShortCode?: Maybe<Scalars['String']>;
  userStatus?: Maybe<Status>;
};

export type OrganizationsConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<OrganizationConnectionNode>;
};

export type OrganizationsConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<OrganizationsConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OrganizationsMutationResult = {
  organizations: Array<OrganizationConnectionNode>;
};

export type PageInfo = {
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type Permission = {
  allow?: Maybe<Scalars['Boolean']>;
  permission_category?: Maybe<Scalars['String']>;
  permission_description?: Maybe<Scalars['String']>;
  permission_group?: Maybe<Scalars['String']>;
  permission_id?: Maybe<Scalars['ID']>;
  permission_level?: Maybe<Scalars['String']>;
  permission_name: Scalars['ID'];
};

export type PermissionFilter = {
  AND?: InputMaybe<Array<PermissionFilter>>;
  OR?: InputMaybe<Array<PermissionFilter>>;
  allow?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  roleId?: InputMaybe<UuidFilter>;
};

export enum PermissionSortBy {
  Category = 'category',
  Group = 'group',
  Id = 'id',
  Level = 'level',
  Name = 'name'
}

export type PermissionSortInput = {
  field: PermissionSortBy;
  order: SortOrder;
};

export type PermissionsConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PermissionsConnectionNode>;
};

export type PermissionsConnectionNode = {
  allow: Scalars['Boolean'];
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  level?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rolesConnection?: Maybe<RolesConnectionResponse>;
};


export type PermissionsConnectionNodeRolesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<RoleFilter>;
  sort?: InputMaybe<RoleSortInput>;
};

export type PermissionsConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<PermissionsConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Program = {
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  age_ranges?: Maybe<Array<AgeRange>>;
  delete?: Maybe<Scalars['Boolean']>;
  editAgeRanges?: Maybe<Array<Maybe<AgeRange>>>;
  editGrades?: Maybe<Array<Maybe<Grade>>>;
  editSubjects?: Maybe<Array<Maybe<Subject>>>;
  grades?: Maybe<Array<Grade>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<Status>;
  subjects?: Maybe<Array<Subject>>;
  system: Scalars['Boolean'];
};


export type ProgramDeleteArgs = {
  _?: InputMaybe<Scalars['Int']>;
};


export type ProgramEditAgeRangesArgs = {
  age_range_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type ProgramEditGradesArgs = {
  grade_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type ProgramEditSubjectsArgs = {
  subject_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProgramConnectionNode = {
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  ageRanges?: Maybe<Array<AgeRangeConnectionNode>>;
  ageRangesConnection?: Maybe<AgeRangesConnectionResponse>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  grades?: Maybe<Array<GradeSummaryNode>>;
  gradesConnection?: Maybe<GradesConnectionResponse>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status: Status;
  /** @deprecated Sunset Date: 07/03/2022 Details: https://calmisland.atlassian.net/l/c/Ts9fp60C */
  subjects?: Maybe<Array<CoreSubjectConnectionNode>>;
  subjectsConnection?: Maybe<SubjectsConnectionResponse>;
  system: Scalars['Boolean'];
};


export type ProgramConnectionNodeAgeRangesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<AgeRangeFilter>;
  sort?: InputMaybe<AgeRangeSortInput>;
};


export type ProgramConnectionNodeGradesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<GradeFilter>;
  sort?: InputMaybe<GradeSortInput>;
};


export type ProgramConnectionNodeSubjectsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SubjectFilter>;
  sort?: InputMaybe<SubjectSortInput>;
};

export type ProgramDetail = {
  age_ranges?: InputMaybe<Array<Scalars['ID']>>;
  grades?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  subjects?: InputMaybe<Array<Scalars['ID']>>;
  system?: InputMaybe<Scalars['Boolean']>;
};

export type ProgramFilter = {
  AND?: InputMaybe<Array<ProgramFilter>>;
  OR?: InputMaybe<Array<ProgramFilter>>;
  ageRangeFrom?: InputMaybe<AgeRangeTypeFilter>;
  ageRangeTo?: InputMaybe<AgeRangeTypeFilter>;
  classId?: InputMaybe<UuidFilter>;
  gradeId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<UuidFilter>;
  schoolId?: InputMaybe<UuidFilter>;
  status?: InputMaybe<StringFilter>;
  subjectId?: InputMaybe<UuidFilter>;
  system?: InputMaybe<BooleanFilter>;
};

export enum ProgramSortBy {
  Id = 'id',
  Name = 'name'
}

export type ProgramSortInput = {
  field: Array<ProgramSortBy>;
  order: SortOrder;
};

export type ProgramsConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ProgramConnectionNode>;
};

export type ProgramsConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<ProgramsConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Query = {
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  ageRangeNode?: Maybe<AgeRangeConnectionNode>;
  ageRangesConnection?: Maybe<AgeRangesConnectionResponse>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  age_range?: Maybe<AgeRange>;
  categoriesConnection?: Maybe<CategoriesConnectionResponse>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  category?: Maybe<Category>;
  categoryNode?: Maybe<CategoryConnectionNode>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  class?: Maybe<Class>;
  classNode?: Maybe<ClassConnectionNode>;
  /** @deprecated Use 'classesConnection'. */
  classes?: Maybe<Array<Maybe<Class>>>;
  classesConnection?: Maybe<ClassesConnectionResponse>;
  eligibleStudentsConnection?: Maybe<UsersConnectionResponse>;
  eligibleTeachersConnection?: Maybe<UsersConnectionResponse>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  grade?: Maybe<Grade>;
  gradeNode?: Maybe<GradeConnectionNode>;
  gradesConnection?: Maybe<GradesConnectionResponse>;
  /** @deprecated Use myUser.node. Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2437513558 */
  me?: Maybe<User>;
  myUser?: Maybe<MyUser>;
  /** @deprecated Use myUser.profiles. Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2437513558 */
  my_users?: Maybe<Array<User>>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  organization?: Maybe<Organization>;
  organizationNode?: Maybe<OrganizationConnectionNode>;
  /** @deprecated Use 'organizationsConnection'. */
  organizations?: Maybe<Array<Maybe<Organization>>>;
  organizationsConnection?: Maybe<OrganizationsConnectionResponse>;
  permissionNode?: Maybe<PermissionsConnectionNode>;
  permissionsConnection?: Maybe<PermissionsConnectionResponse>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  program?: Maybe<Program>;
  programNode?: Maybe<ProgramConnectionNode>;
  programsConnection?: Maybe<ProgramsConnectionResponse>;
  queryComplexity?: Maybe<Complexity>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  role?: Maybe<Role>;
  roleNode?: Maybe<RoleConnectionNode>;
  roles?: Maybe<Array<Maybe<Role>>>;
  rolesConnection?: Maybe<RolesConnectionResponse>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  school?: Maybe<School>;
  schoolNode?: Maybe<SchoolConnectionNode>;
  schoolsConnection?: Maybe<SchoolsConnectionResponse>;
  subcategoriesConnection?: Maybe<SubcategoriesConnectionResponse>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  subcategory?: Maybe<Subcategory>;
  subcategoryNode?: Maybe<SubcategoryConnectionNode>;
  /** @deprecated Sunset Date: 09/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  subject?: Maybe<Subject>;
  subjectNode?: Maybe<SubjectConnectionNode>;
  subjectsConnection?: Maybe<SubjectsConnectionResponse>;
  /** @deprecated Sunset Date: 08/02/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2427683554 */
  user?: Maybe<User>;
  userNode?: Maybe<UserConnectionNode>;
  /** @deprecated Unused */
  users?: Maybe<Array<Maybe<User>>>;
  usersConnection?: Maybe<UsersConnectionResponse>;
};


export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type QueryAgeRangeNodeArgs = {
  id: Scalars['ID'];
};


export type QueryAgeRangesConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<AgeRangeFilter>;
  sort?: InputMaybe<AgeRangeSortInput>;
};


export type QueryAge_RangeArgs = {
  id: Scalars['ID'];
};


export type QueryCategoriesConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<CategoryFilter>;
  sort?: InputMaybe<CategorySortInput>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryCategoryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryClassArgs = {
  class_id: Scalars['ID'];
};


export type QueryClassNodeArgs = {
  id: Scalars['ID'];
};


export type QueryClassesConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<ClassFilter>;
  sort?: InputMaybe<ClassSortInput>;
};


export type QueryEligibleStudentsConnectionArgs = {
  classId: Scalars['ID'];
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<EligibleMembersFilter>;
  sort?: InputMaybe<UserSortInput>;
};


export type QueryEligibleTeachersConnectionArgs = {
  classId: Scalars['ID'];
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<EligibleMembersFilter>;
  sort?: InputMaybe<UserSortInput>;
};


export type QueryGradeArgs = {
  id: Scalars['ID'];
};


export type QueryGradeNodeArgs = {
  id: Scalars['ID'];
};


export type QueryGradesConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<GradeFilter>;
  sort?: InputMaybe<GradeSortInput>;
};


export type QueryOrganizationArgs = {
  organization_id: Scalars['ID'];
};


export type QueryOrganizationNodeArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationsArgs = {
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryOrganizationsConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<OrganizationFilter>;
  sort?: InputMaybe<OrganizationSortInput>;
};


export type QueryPermissionNodeArgs = {
  id: Scalars['ID'];
};


export type QueryPermissionsConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<PermissionFilter>;
  sort?: InputMaybe<PermissionSortInput>;
};


export type QueryProgramArgs = {
  id: Scalars['ID'];
};


export type QueryProgramNodeArgs = {
  id: Scalars['ID'];
};


export type QueryProgramsConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<ProgramFilter>;
  sort?: InputMaybe<ProgramSortInput>;
};


export type QueryRoleArgs = {
  role_id: Scalars['ID'];
};


export type QueryRoleNodeArgs = {
  id: Scalars['ID'];
};


export type QueryRolesConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<RoleFilter>;
  sort?: InputMaybe<RoleSortInput>;
};


export type QuerySchoolArgs = {
  school_id: Scalars['ID'];
};


export type QuerySchoolNodeArgs = {
  id: Scalars['ID'];
};


export type QuerySchoolsConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<SchoolFilter>;
  sort?: InputMaybe<SchoolSortInput>;
};


export type QuerySubcategoriesConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<SubcategoryFilter>;
  sort?: InputMaybe<SubcategorySortInput>;
};


export type QuerySubcategoryArgs = {
  id: Scalars['ID'];
};


export type QuerySubcategoryNodeArgs = {
  id: Scalars['ID'];
};


export type QuerySubjectArgs = {
  id: Scalars['ID'];
};


export type QuerySubjectNodeArgs = {
  id: Scalars['ID'];
};


export type QuerySubjectsConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<SubjectFilter>;
  sort?: InputMaybe<SubjectSortInput>;
};


export type QueryUserArgs = {
  user_id: Scalars['ID'];
};


export type QueryUserNodeArgs = {
  id: Scalars['ID'];
};


export type QueryUsersConnectionArgs = {
  direction: ConnectionDirection;
  directionArgs?: InputMaybe<ConnectionsDirectionArgs>;
  filter?: InputMaybe<UserFilter>;
  sort?: InputMaybe<UserSortInput>;
};

export type RemoveClassesFromSchoolInput = {
  classIds: Array<Scalars['ID']>;
  schoolId: Scalars['ID'];
};

export type RemoveOrganizationRolesFromUserInput = {
  organizationId: Scalars['ID'];
  roleIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type RemoveProgramsFromClassInput = {
  classId: Scalars['ID'];
  programIds: Array<Scalars['ID']>;
};

export type RemoveProgramsFromSchoolInput = {
  programIds: Array<Scalars['ID']>;
  schoolId: Scalars['ID'];
};

export type RemoveSchoolRolesFromUserInput = {
  roleIds: Array<Scalars['ID']>;
  schoolId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RemoveSubcategoriesFromCategoryInput = {
  categoryId: Scalars['ID'];
  subcategoryIds: Array<Scalars['ID']>;
};

export type RemoveUsersFromOrganizationInput = {
  organizationId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type RemoveUsersFromSchoolInput = {
  schoolId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type Role = {
  /** @deprecated Sunset Date: 27/03/2022 Details: https://calmisland.atlassian.net/l/c/8d8mpL0Q */
  delete_role?: Maybe<Scalars['Boolean']>;
  deny?: Maybe<Permission>;
  edit_permissions?: Maybe<Array<Maybe<Permission>>>;
  grant?: Maybe<Permission>;
  memberships?: Maybe<Array<Maybe<OrganizationMembership>>>;
  organization?: Maybe<Organization>;
  permission?: Maybe<Permission>;
  /** @deprecated Sunset Date: 26/02/2022 Details: https://calmisland.atlassian.net/l/c/1nEk2YHE */
  permissions?: Maybe<Array<Maybe<Permission>>>;
  revoke?: Maybe<Scalars['Boolean']>;
  role_description: Scalars['String'];
  role_id: Scalars['ID'];
  role_name?: Maybe<Scalars['String']>;
  /** @deprecated Sunset Date: 03/04/2022 Details: https://calmisland.atlassian.net/l/c/8d8mpL0Q */
  set?: Maybe<Role>;
  status: Status;
  system_role: Scalars['Boolean'];
};


export type RoleDelete_RoleArgs = {
  _?: InputMaybe<Scalars['Int']>;
};


export type RoleDenyArgs = {
  permission_name: Scalars['String'];
};


export type RoleEdit_PermissionsArgs = {
  permission_names?: InputMaybe<Array<Scalars['String']>>;
};


export type RoleGrantArgs = {
  permission_name: Scalars['String'];
};


export type RolePermissionArgs = {
  permission_name: Scalars['String'];
};


export type RoleRevokeArgs = {
  permission_name: Scalars['String'];
};


export type RoleSetArgs = {
  role_description?: InputMaybe<Scalars['String']>;
  role_name?: InputMaybe<Scalars['String']>;
  system_role?: InputMaybe<Scalars['Boolean']>;
};

export type RoleConnectionNode = {
  description: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissionsConnection?: Maybe<PermissionsConnectionResponse>;
  status: Status;
  system: Scalars['Boolean'];
};


export type RoleConnectionNodePermissionsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction: ConnectionDirection;
  filter?: InputMaybe<PermissionFilter>;
  sort?: InputMaybe<PermissionSortInput>;
};

export type RoleFilter = {
  AND?: InputMaybe<Array<InputMaybe<RoleFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<RoleFilter>>>;
  membershipOrganizationId?: InputMaybe<UuidFilter>;
  membershipOrganizationUserId?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<UuidFilter>;
  schoolId?: InputMaybe<UuidFilter>;
  schoolUserId?: InputMaybe<UuidFilter>;
  status?: InputMaybe<StringFilter>;
  system?: InputMaybe<BooleanFilter>;
};

export enum RoleSortBy {
  Id = 'id',
  Name = 'name'
}

export type RoleSortInput = {
  field: RoleSortBy;
  order: SortOrder;
};

export type RoleSummaryNode = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export type RolesConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<RoleConnectionNode>;
};

export type RolesConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<RolesConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type RolesMutationResult = {
  roles: Array<RoleConnectionNode>;
};

export type ScheduleEntry = {
  id: Scalars['ID'];
  timestamp?: Maybe<Scalars['Date']>;
};

export type School = {
  addUser?: Maybe<SchoolMembership>;
  classes?: Maybe<Array<Maybe<Class>>>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/l/c/av1p2bKY */
  delete?: Maybe<Scalars['Boolean']>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/l/c/av1p2bKY */
  editPrograms?: Maybe<Array<Maybe<Program>>>;
  membership?: Maybe<SchoolMembership>;
  memberships?: Maybe<Array<Maybe<SchoolMembership>>>;
  organization?: Maybe<Organization>;
  programs?: Maybe<Array<Program>>;
  school_id: Scalars['ID'];
  school_name?: Maybe<Scalars['String']>;
  set?: Maybe<School>;
  shortcode?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};


export type SchoolAddUserArgs = {
  user_id: Scalars['ID'];
};


export type SchoolDeleteArgs = {
  _?: InputMaybe<Scalars['Int']>;
};


export type SchoolEditProgramsArgs = {
  program_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type SchoolMembershipArgs = {
  user_id: Scalars['ID'];
};


export type SchoolSetArgs = {
  school_name?: InputMaybe<Scalars['String']>;
  shortcode?: InputMaybe<Scalars['String']>;
};

export type SchoolConnectionNode = {
  classesConnection?: Maybe<ClassesConnectionResponse>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  programsConnection?: Maybe<ProgramsConnectionResponse>;
  schoolMembershipsConnection?: Maybe<SchoolMembershipsConnectionResponse>;
  shortCode?: Maybe<Scalars['String']>;
  status: Status;
};


export type SchoolConnectionNodeClassesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<ClassFilter>;
  sort?: InputMaybe<ClassSortInput>;
};


export type SchoolConnectionNodeProgramsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<ProgramFilter>;
  sort?: InputMaybe<ProgramSortInput>;
};


export type SchoolConnectionNodeSchoolMembershipsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SchoolMembershipFilter>;
  sort?: InputMaybe<SchoolMembershipSortInput>;
};

export type SchoolFilter = {
  AND?: InputMaybe<Array<SchoolFilter>>;
  OR?: InputMaybe<Array<SchoolFilter>>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<UuidFilter>;
  programId?: InputMaybe<UuidFilter>;
  schoolId?: InputMaybe<UuidFilter>;
  shortCode?: InputMaybe<StringFilter>;
  status?: InputMaybe<StringFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type SchoolMembership = {
  addRole?: Maybe<Role>;
  addRoles?: Maybe<Array<Maybe<Role>>>;
  checkAllowed?: Maybe<Scalars['Boolean']>;
  join_timestamp?: Maybe<Scalars['Date']>;
  /** @deprecated Sunset Date: 21/03/22 Details: https://calmisland.atlassian.net/l/c/8d8mpL0Q */
  leave?: Maybe<Scalars['Boolean']>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/l/c/ezmgXfSg */
  removeRole?: Maybe<SchoolMembership>;
  roles?: Maybe<Array<Maybe<Role>>>;
  school?: Maybe<School>;
  school_id: Scalars['ID'];
  status?: Maybe<Status>;
  user?: Maybe<User>;
  user_id: Scalars['ID'];
};


export type SchoolMembershipAddRoleArgs = {
  role_id: Scalars['ID'];
};


export type SchoolMembershipAddRolesArgs = {
  role_ids: Array<Scalars['ID']>;
};


export type SchoolMembershipCheckAllowedArgs = {
  permission_name: Scalars['ID'];
};


export type SchoolMembershipLeaveArgs = {
  _?: InputMaybe<Scalars['Int']>;
};


export type SchoolMembershipRemoveRoleArgs = {
  role_id: Scalars['ID'];
};

export type SchoolMembershipConnectionNode = {
  joinTimestamp?: Maybe<Scalars['String']>;
  rolesConnection?: Maybe<RolesConnectionResponse>;
  school?: Maybe<SchoolConnectionNode>;
  schoolId: Scalars['String'];
  status: Status;
  user?: Maybe<UserConnectionNode>;
  userId: Scalars['String'];
};


export type SchoolMembershipConnectionNodeRolesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<RoleFilter>;
  sort?: InputMaybe<RoleSortInput>;
};

export type SchoolMembershipFilter = {
  AND?: InputMaybe<Array<InputMaybe<SchoolMembershipFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SchoolMembershipFilter>>>;
  roleId?: InputMaybe<UuidFilter>;
  schoolId?: InputMaybe<UuidFilter>;
  status?: InputMaybe<StringFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export enum SchoolMembershipSortBy {
  SchoolId = 'schoolId',
  UserId = 'userId'
}

export type SchoolMembershipSortInput = {
  field: SchoolMembershipSortBy;
  order: SortOrder;
};

export type SchoolMembershipsConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SchoolMembershipConnectionNode>;
};

export type SchoolMembershipsConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<SchoolMembershipsConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export enum SchoolSortBy {
  Id = 'id',
  Name = 'name',
  ShortCode = 'shortCode'
}

export type SchoolSortInput = {
  field: Array<SchoolSortBy>;
  order: SortOrder;
};

export type SchoolSummaryNode = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userStatus?: Maybe<Status>;
};

export type SchoolsConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SchoolConnectionNode>;
};

export type SchoolsConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<SchoolsConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SchoolsMutationResult = {
  schools: Array<SchoolConnectionNode>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Status {
  Active = 'active',
  Inactive = 'inactive'
}

export type StringFilter = {
  caseInsensitive?: InputMaybe<Scalars['Boolean']>;
  operator: StringOperator;
  value: Scalars['String'];
};

export enum StringOperator {
  Contains = 'contains',
  Eq = 'eq',
  Neq = 'neq'
}

export type SubcategoriesConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SubcategoryConnectionNode>;
};

export type SubcategoriesConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<SubcategoriesConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubcategoriesMutationResult = {
  subcategories: Array<SubcategoryConnectionNode>;
};

export type Subcategory = {
  /** @deprecated Sunset Date: 10/02/2022 Details: https://bitbucket.org/calmisland/kidsloop-user-service/src/master/documents/rfc/mutations/050-Subcategory-toplevel-mutations.md */
  delete?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<Status>;
  system: Scalars['Boolean'];
};


export type SubcategoryDeleteArgs = {
  _?: InputMaybe<Scalars['Int']>;
};

export type SubcategoryConnectionNode = {
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Status;
  system: Scalars['Boolean'];
};

export type SubcategoryDetail = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  system?: InputMaybe<Scalars['Boolean']>;
};

export type SubcategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<SubcategoryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SubcategoryFilter>>>;
  categoryId?: InputMaybe<UuidFilter>;
  organizationId?: InputMaybe<UuidFilter>;
  status?: InputMaybe<StringFilter>;
  system?: InputMaybe<BooleanFilter>;
};

export enum SubcategorySortBy {
  Id = 'id',
  Name = 'name'
}

export type SubcategorySortInput = {
  field: SubcategorySortBy;
  order: SortOrder;
};

export type Subject = {
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  categories?: Maybe<Array<Category>>;
  delete?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status?: Maybe<Status>;
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  subcategories?: Maybe<Array<Subcategory>>;
  system: Scalars['Boolean'];
};


export type SubjectDeleteArgs = {
  _?: InputMaybe<Scalars['Int']>;
};

export type SubjectConnectionNode = {
  /** @deprecated Sunset Date: 06/03/2022 Details: https://calmisland.atlassian.net/wiki/spaces/ATZ/pages/2473459840 */
  categories?: Maybe<Array<CategoryConnectionNode>>;
  categoriesConnection?: Maybe<CategoriesConnectionResponse>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status: Status;
  system: Scalars['Boolean'];
};


export type SubjectConnectionNodeCategoriesConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<CategoryFilter>;
  sort?: InputMaybe<CategorySortInput>;
};

export type SubjectDetail = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  system?: InputMaybe<Scalars['Boolean']>;
};

export type SubjectFilter = {
  AND?: InputMaybe<Array<InputMaybe<SubjectFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<SubjectFilter>>>;
  categoryId?: InputMaybe<UuidFilter>;
  classId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<UuidFilter>;
  programId?: InputMaybe<UuidFilter>;
  status?: InputMaybe<StringFilter>;
  system?: InputMaybe<BooleanFilter>;
};

export enum SubjectSortBy {
  Id = 'id',
  Name = 'name',
  System = 'system'
}

export type SubjectSortInput = {
  field: SubjectSortBy;
  order: SortOrder;
};

export type SubjectsConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SubjectConnectionNode>;
};

export type SubjectsConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<SubjectsConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SubjectsMutationOutput = {
  subjects: Array<SubjectConnectionNode>;
};

export type UuidExclusiveFilter = {
  operator: UuidExclusiveOperator;
  value?: InputMaybe<Scalars['UUID']>;
};

export enum UuidExclusiveOperator {
  Eq = 'eq',
  IsNull = 'isNull',
  Neq = 'neq'
}

export type UuidFilter = {
  operator: UuidOperator;
  value: Scalars['UUID'];
};

export enum UuidOperator {
  Eq = 'eq',
  Neq = 'neq'
}

export type UpdateCategoryInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  subcategories?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateClassInput = {
  classId: Scalars['ID'];
  className?: InputMaybe<Scalars['String']>;
  shortcode?: InputMaybe<Scalars['String']>;
};

export type UpdateRoleInput = {
  id: Scalars['ID'];
  permissionIds?: InputMaybe<Array<Scalars['ID']>>;
  roleDescription?: InputMaybe<Scalars['String']>;
  roleName?: InputMaybe<Scalars['String']>;
};

export type UpdateSchoolInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  shortCode: Scalars['String'];
};

export type UpdateSubcategoryInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  alternateEmail?: InputMaybe<Scalars['String']>;
  alternatePhone?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  primaryUser?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
};

export type User = {
  /** @deprecated Sunset Date: 01/02/22 Details: https://calmisland.atlassian.net/wiki/spaces/UserService/pages/2462417870/ */
  addOrganization?: Maybe<OrganizationMembership>;
  addSchool?: Maybe<SchoolMembership>;
  alternate_email?: Maybe<Scalars['String']>;
  alternate_phone?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  classesStudying?: Maybe<Array<Maybe<Class>>>;
  classesTeaching?: Maybe<Array<Maybe<Class>>>;
  createOrganization?: Maybe<Organization>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  membership?: Maybe<OrganizationMembership>;
  memberships?: Maybe<Array<Maybe<OrganizationMembership>>>;
  merge?: Maybe<User>;
  /**
   * 'my_organization' is the Organization that this user has created
   * @deprecated Use 'organization_ownerships'.
   */
  my_organization?: Maybe<Organization>;
  organization_ownerships?: Maybe<Array<Maybe<OrganizationOwnership>>>;
  organizationsWithPermission?: Maybe<Array<Maybe<OrganizationMembership>>>;
  phone?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  school_membership?: Maybe<SchoolMembership>;
  school_memberships?: Maybe<Array<Maybe<SchoolMembership>>>;
  schoolsWithPermission?: Maybe<Array<Maybe<SchoolMembership>>>;
  set?: Maybe<User>;
  setPrimary?: Maybe<Scalars['Boolean']>;
  subjectsTeaching?: Maybe<Array<Maybe<Subject>>>;
  user_id: Scalars['ID'];
  /** @deprecated Use 'full_name'. */
  user_name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


export type UserAddOrganizationArgs = {
  organization_id: Scalars['ID'];
};


export type UserAddSchoolArgs = {
  school_id: Scalars['ID'];
};


export type UserCreateOrganizationArgs = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  organization_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  shortCode?: InputMaybe<Scalars['String']>;
};


export type UserMembershipArgs = {
  organization_id: Scalars['ID'];
};


export type UserMergeArgs = {
  other_id?: InputMaybe<Scalars['String']>;
};


export type UserOrganizationsWithPermissionArgs = {
  permission_name: Scalars['String'];
};


export type UserSchool_MembershipArgs = {
  school_id: Scalars['ID'];
};


export type UserSchoolsWithPermissionArgs = {
  permission_name: Scalars['String'];
};


export type UserSetArgs = {
  alternate_email?: InputMaybe<Scalars['String']>;
  alternate_phone?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  family_name?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  given_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type UserSetPrimaryArgs = {
  _?: InputMaybe<Scalars['Int']>;
};

export type UserConnectionNode = {
  alternateContactInfo?: Maybe<ContactInfo>;
  avatar?: Maybe<Scalars['String']>;
  classesStudyingConnection?: Maybe<ClassesConnectionResponse>;
  classesTeachingConnection?: Maybe<ClassesConnectionResponse>;
  contactInfo: ContactInfo;
  dateOfBirth?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  organizationMembershipsConnection?: Maybe<OrganizationMembershipsConnectionResponse>;
  /** @deprecated Sunset Date: 31/01/22 Details: https://calmisland.atlassian.net/l/c/7Ry00nhw */
  organizations?: Maybe<Array<OrganizationSummaryNode>>;
  /** @deprecated Sunset Date: 31/01/22 Details: https://calmisland.atlassian.net/l/c/7Ry00nhw */
  roles?: Maybe<Array<RoleSummaryNode>>;
  schoolMembershipsConnection?: Maybe<SchoolMembershipsConnectionResponse>;
  /** @deprecated Sunset Date: 31/01/22 Details: https://calmisland.atlassian.net/l/c/7Ry00nhw */
  schools?: Maybe<Array<SchoolSummaryNode>>;
  status: Status;
};


export type UserConnectionNodeClassesStudyingConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<ClassFilter>;
  sort?: InputMaybe<ClassSortInput>;
};


export type UserConnectionNodeClassesTeachingConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<ClassFilter>;
  sort?: InputMaybe<ClassSortInput>;
};


export type UserConnectionNodeOrganizationMembershipsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<OrganizationMembershipFilter>;
  sort?: InputMaybe<OrganizationMembershipSortBy>;
};


export type UserConnectionNodeSchoolMembershipsConnectionArgs = {
  count?: InputMaybe<Scalars['PageSize']>;
  cursor?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<ConnectionDirection>;
  filter?: InputMaybe<SchoolMembershipFilter>;
  sort?: InputMaybe<SchoolMembershipSortInput>;
};

export type UserFilter = {
  AND?: InputMaybe<Array<UserFilter>>;
  OR?: InputMaybe<Array<UserFilter>>;
  avatar?: InputMaybe<StringFilter>;
  classId?: InputMaybe<UuidExclusiveFilter>;
  email?: InputMaybe<StringFilter>;
  familyName?: InputMaybe<StringFilter>;
  givenName?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<UuidFilter>;
  organizationUserStatus?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  roleId?: InputMaybe<UuidFilter>;
  schoolId?: InputMaybe<UuidExclusiveFilter>;
  userId?: InputMaybe<UuidFilter>;
  userStatus?: InputMaybe<StringFilter>;
};

export type UserPermissionStatus = {
  allowed: Scalars['Boolean'];
  permissionId: Scalars['String'];
};

export enum UserSortBy {
  FamilyName = 'familyName',
  GivenName = 'givenName'
}

export type UserSortInput = {
  field: Array<UserSortBy>;
  order: SortOrder;
};

export type UserSummaryNode = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type UsersConnectionEdge = IConnectionEdge & {
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<UserConnectionNode>;
};

export type UsersConnectionResponse = IConnectionResponse & {
  edges?: Maybe<Array<Maybe<UsersConnectionEdge>>>;
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UsersMutationResult = {
  users: Array<UserConnectionNode>;
};

export type _Entity = User | UserConnectionNode;

export type _Service = {
  /** The sdl representing the federated service capabilities. Includes federation directives, removes federation types, and includes rest of full schema after schema directives have been applied */
  sdl?: Maybe<Scalars['String']>;
};

export type IConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
};

export type IConnectionResponse = {
  pageInfo?: Maybe<ConnectionPageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type ProfileFragment = { id: string, givenName?: string | null | undefined, familyName?: string | null | undefined, dateOfBirth?: string | null | undefined };

export type ProfilesVariables = Exact<{ [key: string]: never; }>;


export type Profiles = { myUser?: { profiles: Array<{ id: string, givenName?: string | null | undefined, familyName?: string | null | undefined, dateOfBirth?: string | null | undefined }> } | null | undefined };

export const ProfileFragment = gql`
    fragment ProfileFragment on UserConnectionNode {
  id
  givenName
  familyName
  dateOfBirth
}
    `;
export const ProfilesDocument = gql`
    query profiles {
  myUser {
    profiles {
      ...ProfileFragment
    }
  }
}
    ${ProfileFragment}`;

/**
 * __useProfiles__
 *
 * To run a query within a React component, call `useProfiles` and pass it any options that fit your needs.
 * When your component renders, `useProfiles` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfiles({
 *   variables: {
 *   },
 * });
 */
export function useProfiles(baseOptions?: Apollo.QueryHookOptions<Profiles, ProfilesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Profiles, ProfilesVariables>(ProfilesDocument, options);
      }
export function useProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Profiles, ProfilesVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Profiles, ProfilesVariables>(ProfilesDocument, options);
        }
export type ProfilesHookResult = ReturnType<typeof useProfiles>;
export type ProfilesLazyQueryHookResult = ReturnType<typeof useProfilesLazyQuery>;
export type ProfilesQueryResult = Apollo.QueryResult<Profiles, ProfilesVariables>;